input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 16px;
  width: 16px;
  background-color: #22c55e; /* Green thumb */
  border-radius: 50%;
}

input[type="range"]::-moz-range-thumb {
  background-color: #22c55e; /* Green thumb */
  height: 16px;
  width: 16px;
  border-radius: 50%;
}