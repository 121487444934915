@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import "@shadcn/ui/styles"; */

body {
  font-family: "DM Sans";
  width: 100%;
  overflow-x: hidden !important;
}

@media screen and (max-width: 560px) {
  :root {
    font-size: 14px;
  }
}

.leadinnd_data {
  height: 40vh;

  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  pointer-events: none;
}

.leadinnd_data::-webkit-scrollbar {
  display: none;
}
.tabs::-webkit-scrollbar {
  display: none;
}
/* table {
  box-shadow: 0px 0px 4px 1px rgba(49, 61, 49, 0.08);
  background: #fff;
}
tr {
  box-shadow: 0px 0px 4px 1px rgba(49, 61, 49, 0.08);

  border-bottom: 1px solid #d4d4d4;
} */

/* Remove border from the last tr element */
tbody tr:last-child {
  border-bottom: none;
}
a.active {
  @apply bg-[#f4fbf6] text-lime-green;
}
.custom-checkbox {
  @apply accent-[green] w-4 h-4 border-gray-300 rounded-sm focus:ring-[#50c878];
}

.custom-button-border {
  border-bottom-left-radius: 4px; /* Adjust radius as needed */
  border-bottom-right-radius: 4px;
  border-bottom: 2px solid white; /* Adjust color and size */
}

@media screen and (max-width: 1350px) {
  :root {
    font-size: 14px;
  }
}

@media screen and (max-width: 1200px) {
  :root {
    font-size: 12px;
  }
}

.custom_ellipses .flex > .text-xs {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ellipses {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.select__indicator-separator {
  display: none;
}

.select__clear-indicator {
  padding-right: 0px !important;
}

.select__indicator {
  color: #777977 !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.link-style {
  position: relative;
  display: inline-block;
}

.link-style::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #50c878;
  transition: width 0.3s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
}

.link-style:hover::after {
  width: 100%;
}

.footer-link {
  position: relative;
  display: inline-block;
}

.footer-link::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: white;
  transition: width 0.3s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
}

.footer-link:hover::after {
  width: 100%;
}
.css-13cymwt-control {
  cursor: pointer !important;
  outline: none !important;
}

.css-tr4s17-option {
  background-color: #50c878 !important;
}
.css-d7l1ni-option {
  background-color: #86f7ab !important;
  cursor: pointer !important;
}
.css-d7l1ni-option:hover {
  background-color: #86f7ab !important;
}
td {
  max-width: 200px;
}
@media (max-width: 1560px) {
  table {
    table-layout: auto;
  }
}

.custom-placeholder .select__placeholder {
  font-size: 12px !important;
}
